import React, { useState, useEffect, useContext, useMemo } from "react"
import { Chart, Doughnut, Pie  } from 'react-chartjs-2';
import 'chart.js/auto'; // ADD THIS

import { ProviderContext } from "../App";
import Navbar from "../components/Navbar";
import BottomNavbar from "../components/BottomNavbar";
import FilterCard from "../components/Cards/FilterCard";
import RequestCard from "../components/Cards/RequestCard"
import Ring from "../components/ring";

import StatCard from "../components/Cards/StatCard";
import StatCardRound from "../components/Cards/StatCardRound";

import { Player, Controls } from '@lottiefiles/react-lottie-player';

import Home from "../subroutes/Admin/Home";
import Requests from "../subroutes/Admin/Requests";
import Settings from "../subroutes/Admin/Settings";



/* eslint-disable padded-blocks */
 

let Admin = (props) => {
    const {profile,env} = useContext(ProviderContext)
    const [data, isData] = useState([])
    const [selected, isSelected] = useState('Dashboard')
    const [users, isUsers] = useState([])
    const [loaded, isLoaded] = useState(false)
    const [statsRequest, isStatsRequest] = useState({
        approved:0,
        pending:0,
        rejected:0,
        total:0, 
        spent:0,
    })

    const [spentMonthly, isSpentMonthly] = useState({
        sep:0,oct:0,nov:0,dec:0,jan:0,feb:0,mar:0,apr:0,may:0,jun:0,jul:0,aug:0
    })

    const [budgetPerDepartment, isBudgetPerDepartment] = useState({
        label: [],
        data: []
    })
    const [animate, isAnimate] = useState(true)
    const [fiscalBudget, setFiscalBudget] = useState(null)

    
    let getFiscalBudget = async () => {
        const requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
    
        try {
            const response = await fetch(`${env}/api/capex/getFiscalBudget`, requestOptions);
            const result = await response.json();
            console.log('fiscal budget ==> ',result)
            setFiscalBudget(result[0])
        } catch (error) {
            console.log('error', error);
        }
    }
    let getRequests = async () => {
        // console.log('check for new requests...');
    
        const requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
    
        try {
            const response = await fetch(`${env}/api/capex/getRequestsAll`, requestOptions);
            const result = await response.json();
            // console.log('all requests ==> ', result);
    
            let approved = 0;
            let pending = 0;
            let rejected = 0;
            let spent = 0;
            let months = {};
            let label = [];
            let dataLabel = [];
    
            result.forEach(res => {
                if (res.Status === 'pending' || !res.Status) {
                    pending += 1;
                } else if (res.Status === 'approved') {
                    approved += 1;
                    spent += parseInt(res.EstimatedValue);
                    
                    months[res.ApprovalDateMonth] = (months[res.ApprovalDateMonth] || 0) + parseInt(res.EstimatedValue);
    
                    if (!label.includes(res.Department)) {
                        label.push(res.Department);
                        dataLabel.push(parseInt(res.EstimatedValue));
                    } else {
                        const index = label.indexOf(res.Department);
                        dataLabel[index] += parseInt(res.EstimatedValue);
                    }
                } else {
                    rejected += 1;
                }
            });
    
            // console.log('months registered ===> ', months);
            // console.log('amount spent ===> ', spent);
    
            isData(result);
            isStatsRequest({
                total: approved + pending + rejected,
                approved: approved,
                pending: pending,
                rejected: rejected,
                spent: spent,
            });
    
            isBudgetPerDepartment({
                label: label,
                data: dataLabel
            });
    
            isSpentMonthly({
                oct: months['oct'] || 0,
                nov: months['nov'] || 0,
                dec: months['dec'] || 0,
                jan: months['jan'] || 0,
                feb: months['feb'] || 0,
                mar: months['mar'] || 0,
                apr: months['apr'] || 0,
                may: months['may'] || 0,
                jun: months['jun'] || 0,
                jul: months['jul'] || 0,
                aug: months['aug'] || 0,
                sep: months['sep'] || 0
            });
    
            // console.log('stats request ===> ', statsRequest);
            isLoaded(true);
    
            setTimeout(getRequests, 5000);
        } catch (error) {
            console.log('error', error);
        }
    };
    

    let getRegisteredUsers = async () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
          
        await fetch(`${env}/api/capex/getRegisteredUser`, requestOptions)
        .then(response => response.text())
        .then(result => {
            // console.log('all users ==> ', JSON.parse(result))
            let res = JSON.parse(result)
           
            isUsers(res)

            setTimeout(getRegisteredUsers, 5000)

        })
        .catch(error => {
            console.log('error', error)
        });

    }
   

    useMemo(() => {
        getRequests()
        getRegisteredUsers()
        getFiscalBudget()
    },[])


  
    return (
        <div className="row h-100" >

       
          
            <div className='col-xl-2 col-lg-2 col-md-2 d-flex h-100 mobilecontrol' style={{justifyContent:'center',alignItems:'center'}}>
                <Navbar 
                    onClick1={() => isSelected('Dashboard')}
                    onClick2={() => isSelected('Requests')}
                    onClick3={() => isSelected('Settings')}
                    Selected = {selected}
                />
            </div>

            {loaded ?
                (
                    <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 h-100">
                        {selected == 'Dashboard' &&
                            <Home FiscalBudget={fiscalBudget} Loaded={loaded} StatsRequest={statsRequest} SpentMonthly={spentMonthly} PerDepartment={budgetPerDepartment} Users={users}  />
                        }
        
                        {selected == 'Requests' &&
                            <Requests env={env} data={data} />
                        }
        
                        {selected == 'Settings' &&
                            <Settings Users={users} FiscalBudget={fiscalBudget} />
                        }
        
                        <BottomNavbar 
                            onClick1={() => isSelected('Dashboard')}
                            onClick2={() => isSelected('Requests')}
                            onClick3={() => isSelected('Settings')}
                            Selected = {selected}
                        />
                    
                    </div>
                )

                :

                (
                    <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 h-100">
                        
                        <div className={`custom-card animate__animated animate__fadeIn`}>
                            <div class="d-flex justify-content-center w-100 h-100">
                                <div class="spinner-border text-primary loader" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                )
            }
           
           
        </div>
    )
}

export default Admin