import React, { useState, useEffect, useMemo } from "react"

import FilterCard from "../../components/Cards/FilterCard";
import RequestCard from "../../components/Cards/RequestCard"
import Ring from "../../components/ring";
import { formatNumberWithCommasAndDots } from "../../components/functions";

 /* eslint-disable padded-blocks */
 

function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

let Requests = (props) => {
    
    const data = props.Data
    const [selected, isSelected] = useState(0)
    const [search, isSearching] = useState(false)
    const [searchRes, isSearchRes] = useState([])
    const [activeFilter,isActiveFilter] = useState('Default')
    const [animate, isAnimate] = useState(true)
    const [currValue, isCurrValue] = useState(0)
    const [currRequest, isCurrRequest] = useState(null)

    const [downloadQuote, setDownloadQuote] = useState(null)
    const [downloadFormat, setDownloaFormat] = useState(null)

    console.log('request data is ===> ',data)

    const printRequest = () => {

        
        isSelected(1)
        
        setTimeout(() => {
            window.print()
            isSelected(0)
        }, 1000);

      
      
    }
   
    let formatText = (text) => { 
        if(text.toLowerCase() == 'pending') return ' Approval'
        else return ''
    }

    function dataURItoFile(dataURI) {
        // Extract the Base64 content
        const base64Content = dataURI.split(',')[1];
        const fileType = dataURI.split('/')[1].split(';')[0]
      
        // Decode Base64 string into binary data
        const binaryString = atob(base64Content);
      
        // Create a typed array from binary string
        const byteArray = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
          byteArray[i] = binaryString.charCodeAt(i);
        }
      
        // Create Blob from the typed array with appropriate MIME type
        const blob = new Blob([byteArray], { type: dataURI.split(';')[0] });
      
        // Optionally, create a URL for the Blob
        const fileURL = URL.createObjectURL(blob);
         
        let getFileTypeFromDataURI = (type) => {
            
            switch (type) {
                case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
                    return 'docx';
                case 'pdf':
                    return 'pdf';
                case 'png':
                    return 'png';
                case 'jpeg':
                    return 'jpg';
                case 'jpg':
                    return 'jpg';
                default:
                    throw new Error('Unsupported file type');
            }
        }
      
        console.log('converted blob file ===> ',fileURL)
      
        setDownloadQuote(fileURL)
        setDownloaFormat(getFileTypeFromDataURI(fileType))
        // return blob; // or return the Blob object if needed
    }
    
    let renderCards = data?.map((data) => {

        let getDate = () => {
            if (data.Status !== 'pending' && data.Approval4Date) return data.Approval4Date.toString().substring(0,10)
            else if (data.Status !== 'pending' && data.Approval3Date) return data.Approval3Date.toString().substring(0,10)
            else if (data.Status !== 'pending' && data.Approval2Date) return data.Approval2Date.toString().substring(0,10)
            else if (data.Status !== 'pending' && data.Approval1Date) return data.Approval1Date.toString().substring(0,10)
            else return 'N/A'
        }
       
        return (
            <RequestCard 
                ID = {data.ID}
                Request={data.ItemDescription? data.ItemDescription : 'N / A'}  
                Requestor={data.OriginatorName}
                Budget={data.EstimatedValue}
                Status = {data.Status? capitalizeFirstLetter(data.Status) + formatText(data.Status) : 'Pending Approval'}
                Date = {getDate()}
                onClick={() => {
                    let int = parseInt(data.EstimatedValue)
                    isCurrValue(int)
                    isCurrRequest(data)
                    console.log(typeof(int))

                    setDownloadQuote(null)
                    setDownloaFormat(null)

                    if(data?.QuoteValue?.length > 10){
                        console.log('convert to blob')
                        dataURItoFile(data.QuoteValue)
                    }
                }}
            />
        )
       
    })
    let searchCards = searchRes?.map((data) => {

        let getDate = () => {
            if (data.Status !== 'pending' && data.Approval4Date) return data.Approval4Date.toString().substring(0,10)
            else if (data.Status !== 'pending' && data.Approval3Date) return data.Approval3Date.toString().substring(0,10)
            else if (data.Status !== 'pending' && data.Approval2Date) return data.Approval2Date.toString().substring(0,10)
            else if (data.Status !== 'pending' && data.Approval1Date) return data.Approval1Date.toString().substring(0,10)
            else return 'N/A'
        }
       
        return (
            <RequestCard 
                ID = {data.ID}
                Request={data.ItemDescription? data.ItemDescription : 'N / A'}  
                Requestor={data.OriginatorName}
                Budget={data.EstimatedValue}
                Status = {data.Status? capitalizeFirstLetter(data.Status) + formatText(data.Status) : 'Pending Approval'}
                Date = {getDate()}
                onClick={() => {
                    let int = parseInt(data.EstimatedValue)
                    isCurrValue(int)
                    isCurrRequest(data)
                    console.log(typeof(int))

                    setDownloadQuote(null)
                    setDownloaFormat(null)

                    if(data?.QuoteValue?.length > 10){
                        console.log('convert to blob')
                        dataURItoFile(data.QuoteValue)
                    }
                }}
            />
        )
       
    })

    return (
        <div className="row h-100" >

            <div className="modal" id="detailsCard"  tabIndex="-1" data-bs-keyboard="false">
                <div className="modal-dialog modal-fullscreen animate__animated animate__zoomInUp animate__faster">
                    
                    <div className="row w-100 h-100" style={{height:'70%'}}>
                        <div className="col-12 center">
                            <div className='details-card'>

                                <span 
                                    className="material-symbols-outlined btn-close" 
                                    data-bs-dismiss="modal" aria-label="Close"
                                    style={{
                                        position:'absolute',
                                        right:'3%'
                                    }}
                                >close</span>

                               
                                <div className="w-100 h-100" style={{overflowY:'auto',overflowX:'hidden'}}>
                                   
                                    

                                    <div className="blue-card">
                                        <div className="row h-100">
                                            <div className="col-12 h-100" style={{position:'relative'}}>
                                                <div className="row">
                                                    <div className="col" style={{position:'relative'}}>
                                                        <Ring 
                                                            Status ={currRequest?.Approval1}
                                                            Text =  {currRequest?.Approval1 == 'pending'? 'Pending approval from Department' : `Request ${currRequest?.Approval1} by ${currRequest?.Approval1ByName} on ${new Date(currRequest?.Approval1Date).toString().split(' ').slice(0, 4).join(' ')}`} 
                                                        />

                                                        {currRequest?.Approval1 !== 'denied' && 
                                                            <div className={`connectLine ${currRequest?.ApprovalRequired >= 2 && animate ? 'active' : '' } ${currRequest?.Approval1 == 'approved'? 'approved' : ''}`} />
                                                        }
                                                    </div>
                                                  
                                                    {currRequest?.Approval1 !== 'denied' &&
                                                        <div className="col" style={{position:'relative'}}>
                                                            <Ring 
                                                                Status ={currRequest?.Approval2}
                                                                Text = {currRequest?.Approval2 == 'pending'? 'Pending approval from Accounting' : `Request ${currRequest?.Approval2} by ${currRequest?.Approval2ByName} on ${new Date(currRequest?.Approval2Date).toString().split(' ').slice(0, 4).join(' ')}`}
                                                            />

                                                            {(currRequest?.Approval2 !== 'denied' && currRequest?.Approval3) &&
                                                                <div className={`connectLine ${currRequest?.ApprovalRequired >= 2 && animate ? 'active' : '' } ${currRequest?.Approval2 == 'approved'? 'approved' : ''}`} />
                                                            }
                                                        </div>
                                                    } 

                                                    {currRequest?.ApprovalRequired >= 3 && currRequest?.Approval2 !== 'denied' && currRequest?.Approval1 !== 'denied' &&
                                                        <div className="col" style={{position:'relative'}}>
                                                            <Ring 
                                                                Status ={currRequest?.Approval3}
                                                                Text = {currRequest?.Approval3 == 'pending'? 'Pending approval from CEO' : `Request ${currRequest?.Approval3} by ${currRequest?.Approval3ByName} on ${new Date(currRequest?.Approval3Date).toString().split(' ').slice(0, 4).join(' ')}`}
                                                            />

                                                            {currRequest?.Approval3 !== 'denied' &&
                                                                <div className={`connectLine ${currRequest?.ApprovalRequired >=4 && animate ? 'active' : '' } ${currRequest?.Approval3 == 'approved'? 'approved' : ''}`} />
                                                            }
                                                        </div>
                                                    }

                                                    {currRequest?.ApprovalRequired >= 4 && currRequest?.Approval3 !== 'denied' && currRequest?.Approval2 !== 'denied' && currRequest?.Approval1 !== 'denied' &&
                                                        <div className="col" style={{position:'relative'}}>
                                                            <Ring 
                                                                Status ={currRequest?.Approval4}
                                                                Text = {currRequest?.Approval4 == 'pending'? 'Pending approval from President' : `Request ${currRequest?.Approval4} by ${currRequest?.Approval2ByName} on ${new Date(currRequest?.Approval4Date).toString().split(' ').slice(0, 4).join(' ')}`}
                                                            />
                                                            
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                        
                                        </div>
                                    </div>

                                    <br/>

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row blue-card g-2 needs-validation testform" novalidate>
                                                <h5>Purchase</h5>
                                                <div className="col-xl-6 col-sm-12">
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon1">Asset Category</span>
                                                        <input className="form-control" value={currRequest?.AssetCategory} disabled  />
                                                    </div>

                                                    <div className="input-group mb-3 flex-nowrap">
                                                        <span className="input-group-text" id="basic-addon1">Quantity</span>
                                                        <input className="form-control" value={currRequest?.Quantity} disabled  />
                                                    </div>
                                                    
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text " id="basic-addon1">Justification of Purchase</span>
                                                        <textarea className="form-control" value={currRequest?.JustificationOfPurchase} disabled  />
                                                    </div>

                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon1">Ship To Address</span>
                                                        <textarea className="form-control" value={currRequest?.ShipToAddress} disabled  />
                                                    </div>
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon1">City/State/Zip</span>
                                                        <textarea className="form-control" value={currRequest?.AddressTo} disabled  />
                                                    </div>
                                                </div>
                                                

                                                <div className="col-xl-6 col-sm-12">
                                                    <div className="input-group mb-3">
                                                            <span className="input-group-text" id="basic-addon1">CAPEX or PURCHASE REQ</span>
                                                            <input className="form-control" value={currRequest?.ID} disabled  />
                                                        </div>
                                                        
                                                        <div className="input-group mb-3" style={{position:'relative'}}>
                                                            <span className="input-group-text" id="basic-addon1">Part Number</span>
                                                            <input className="form-control" value={currRequest?.PartNumber} disabled  />
                                                        </div>
                                                        
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text" id="basic-addon1">Item Description</span>
                                                            <textarea className="form-control" value={currRequest?.ItemDescription} disabled  />
                                                        </div>

                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text" id="basic-addon1">Location</span>
                                                            <textarea className="form-control" value={currRequest?.AddressFrom} disabled  />
                                                        </div>
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text" id="basic-addon1">City/State/Zip</span>
                                                            <textarea className="form-control" value={currRequest?.Location} disabled  />
                                                        </div>
                                                    </div>
                                                
                                            </div>
                                        </div>
                                    </div>

                                    <br/>
        
                                    <div className="row printpage">
                                        <div className="col-12">
                                            <div className="blue-card g-2 row">
                                                <h5>Cost</h5>
                                                <div className="col-xl-6 col-sm-12">
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon1">Vendor</span>
                                                        <input className="form-control" value={currRequest?.Vendor} disabled  />
                                                    </div>

                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon1">Quote #</span>
                                                        <input className="form-control" value={currRequest?.Quote} disabled  />
                                                    </div>

                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon1">Quote Attachment</span>

                                                        {downloadQuote?
                                                            (
                                                                <>
                                                                    <input className="form-control" disabled />
                                                                    <a className='quoteDownload' href={downloadQuote} download={currRequest?.ID + `_quote.${downloadFormat}`}>Download</ a>
                                                                </>
                                                                
                                                                    
                                                               
                                                            )

                                                            :

                                                            (
                                                                <input className="form-control" value='There is no attached quote' disabled  />
                                                            )
                                                        }
                                                        
                                                    </div>

                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon1">Department</span>
                                                        <input className="form-control" value={currRequest?.Department} disabled  />
                                                    </div>


                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon1">Budgeted</span>
                                                        <input className="form-control" value={currRequest?.Budgeted == 'true'? 'Yes' : 'No'} disabled  />
                                                    </div>


                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon1">Unbudgeted ROI</span>
                                                        <input className="form-control" value={currRequest?.Unbudgeted? currRequest?.Unbudgeted : 'N/A'} disabled  />
                                                    </div>
                                                </div>
                                                

                                                <div className="col-xl-6 col-sm-12">
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon1">Quote Date</span>
                                                        <input className="form-control" value={currRequest?.QuoteDate} disabled  />
                                                        
                                                    </div>
                                                        
                                                    <div className="input-group mb-3" style={{position:'relative'}}>
                                                        <span className="input-group-text" id="basic-addon1">Estimated Value</span>
                                                        <input className="form-control" value={formatNumberWithCommasAndDots(currRequest?.EstimatedValue)} disabled  />
                                                        <span className="input-group-text">$</span>
                                                    
                                                    </div>
                                                    
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon1">Project Code</span>
                                                        <input className="form-control" value={currRequest?.ProjectCode} disabled  />
                                                    </div>

                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon1">Budget Code</span>
                                                        <input className="form-control" value={(currRequest?.BudgetCode == 'none' || currRequest?.BudgetCode == '')? 'N/A' : currRequest?.BudgetCode} disabled  />
                                                    </div>

                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon1">Budget Amount</span>
                                                        <input className="form-control" value={formatNumberWithCommasAndDots(currRequest?.BudgetAmount)} disabled  />
                                                        <span className="input-group-text">$</span>
                                                    </div>
                                                    
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                
                                    <br/>
        
                                    <div class="row printpage">

                                        <div class="col-12">
                                            <div className="blue-card row g-2" style={{marginBottom:'5%'}}>
                                                <h5>Contact</h5>
                                                <div className="col-xl-6 col-sm-12">
                                                    <div class="input-group mb-3">
                                                        <span class="input-group-text" id="basic-addon1">Originator</span>
                                                        <input className="form-control" value={currRequest?.OriginatorName} disabled  />
                                                    </div>


                                                    <div class="input-group mb-3">
                                                        <span class="input-group-text" id="basic-addon1">Contact Phone Number</span>
                                                        <input className="form-control" value={currRequest?.Phone} disabled  />
                                                    </div>


                                                
                                                </div>

                                                <div className="col-xl-6 col-sm-12">
                                                
                                                    <div class="input-group mb-3">
                                                        <span class="input-group-text" id="basic-addon1">Request Date</span>
                                                        <input className="form-control" value={currRequest?.DateRequest} disabled  />
                                                    </div>

                                                    <div class="input-group mb-3">
                                                        <span class="input-group-text" id="basic-addon1">Date Needed</span>
                                                        <input className="form-control" value={currRequest?.DateNeeded} disabled  />
                                                    
                                                    </div>
                                                </div>
                                                

                                            
                                            </div>
                                        </div>


                                    </div>

                                    
                                    <div className="row mb-5 toprint">
                                        <div className="col-12">
                                            <div className="blue-card row g-2 bottomApproval" style={{justifyContent:'center'}}>
                                                <div 
                                                    style={{borderRadius:100}} 
                                                    className="btn dld custom btn-primary"
                                                    data-bs-dismiss="modal"
                                                    onClick={() => setTimeout(() => {
                                                        printRequest()
                                                    }, 350)}
                                                    
                                                >
                                                    Download PDF
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   
                                 

                                 
                                </div>
                            </div>
                        </div>
                    </div>

                    
                </div>
            </div>
          
            {selected == 0?
                (
                    <div className="col-12 h-100">
                        <div className={`custom-card contained animate__animated animate__fadeIn`}>
        
                            <div className='w-100 mb-3 text-center' style={{position:'relative',margin:'auto'}}>
                                <span class="material-symbols-outlined search-icon">search</span>
                                <input 
                                
                                    placeholder='Search for request...' 
                                    className='searchCustom text-center'
                                    onChange={(e) => {
                                        let text = e.target.value
                                        console.log(text)
                                        
        
                                        // console.log(data.filter(mydata => mydata.FirstName.includes(text)))
                                        
                                    
                                        if(text.length > 0){
                                            isSearching(true)
                                            isSearchRes(data.filter(mydata => mydata.ItemDescription?.toLowerCase().includes(text) || mydata.PartNumber?.toLowerCase().includes(text) || mydata.OriginatorName?.toLowerCase().includes(text) || mydata.ID?.toLowerCase().includes(text)))
                                        }
                                        else{
                                            isSearching(false)
                                        }
                                        
                                    
                                    }}
                                />
                                
                            </div>
        
                            <div className="filterContainer d-flex w-100 justify-content-center" style={{position:'relative'}}>
                                <p >Filter by</p>
                            </div>
        
                            <div className="d-flex w-100" style={{marginTop:'.1%',margin:'auto',marginBottom:'1%'}}>
        
                                <div className="filterContainer d-flex w-100 justify-content-center" style={{position:'relative'}}>
                                    
        
                                    <FilterCard
                                        Name='Default'
                                        Active={activeFilter} 
                                        onClick={() => {
                                            isSearching(false)
                                            isActiveFilter('Default')
                                        }}
                                        
                                    />
        
                                    <FilterCard
                                        Name='Approved'
                                        Active={activeFilter} 
                                        onClick={() => {
                                            isSearching(true)
                                            isSearchRes(data.filter(mydata => mydata.Status?.toLowerCase().includes('approved') ))
                                            isActiveFilter('Approved')
                                        }}
                                        
                                    />
        
                                    <FilterCard
                                        Name='Pending Approval'
                                        Active={activeFilter} 
                                        onClick={() => {
                                            isSearching(true)
                                            isSearchRes(data.filter(mydata => !mydata.Status || mydata.Status?.toLowerCase().includes('pending') ))
                                            isActiveFilter('Pending Approval')
                                        }}
                                        
                                    />
        
                                    <FilterCard
                                        Name='Rejected'   
                                        Active={activeFilter} 
                                        onClick={() => {
                                            isSearching(true)
                                            isSearchRes(data.filter(mydata => mydata.Status?.toLowerCase().includes('denied') ))
                                            isActiveFilter('Rejected')
                                        }}
                                        
                                    />
        
                                    {/* <FilterCard
                                        Name='Newest'
                                        Active={activeFilter} 
                                        onClick={() => {
                                            isSearching(true)
                                            isSearchRes(data.filter(mydata => mydata.Status?.toLowerCase().includes('complete') ))
                                            isActiveFilter('Newest')
                                        }}
                                        
                                    />
        
                                    <FilterCard
                                        Name='Oldest'
                                        Active={activeFilter} 
                                        onClick={() => {
                                            isSearching(true)
                                            isSearchRes(data.filter(mydata => mydata.Status?.toLowerCase().includes('complete') ))
                                            isActiveFilter('Oldest')
                                        }}
                                    /> */}
                                </div>
                            
                                
                            
                                
                            </div>
        
                            <div className="row w-100" style={{margin:'auto'}}>
                                <>
                                    {!search?
                                        (
                                            <>{renderCards}</>
                                        )
                                        :
                                        (
                                            <>{searchCards}</>
                                        )
                                    }
                                
                                </>
                            </div>
                        </div>
                    </div>
                )

                :

                (
                    <div id="requestContent" className="w-100 h-100">
                                   
                                    

                        <div className="blue-card">
                            <div className="row h-100">
                                <div className="col-12 h-100" style={{position:'relative'}}>
                                    <div className="row">
                                        <div className="col" style={{position:'relative'}}>
                                            <Ring 
                                                Status ={currRequest?.Approval1}
                                                Text =  {currRequest?.Approval1 == 'pending'? 'Pending approval from Department' : `Request has been ${currRequest?.Approval1} by ${currRequest?.Approval1ByName}`} 
                                            />

                                            {currRequest?.Approval1 !== 'denied' && 
                                                <div className={`connectLine ${currRequest?.ApprovalRequired >= 2 && animate ? 'active' : '' } ${currRequest?.Approval1 == 'approved'? 'approved' : ''}`} />
                                            }
                                        </div>
                                        
                                        {currRequest?.Approval1 !== 'denied' &&
                                            <div className="col" style={{position:'relative'}}>
                                                <Ring 
                                                    Status ={currRequest?.Approval2}
                                                    Text = {currRequest?.Approval2 == 'pending'? 'Pending approval from Accounting' : `Request has been ${currRequest?.Approval2} by ${currRequest?.Approval2ByName}`}
                                                />

                                                {(currRequest?.Approval2 !== 'denied' && currRequest?.Approval3) &&
                                                    <div className={`connectLine ${currRequest?.ApprovalRequired >= 2 && animate ? 'active' : '' } ${currRequest?.Approval2 == 'approved'? 'approved' : ''}`} />
                                                }
                                            </div>
                                        } 
                                        

                                        {currRequest?.ApprovalRequired >= 3 && currRequest?.Approval2 !== 'denied' && currRequest?.Approval1 !== 'denied' &&
                                            <div className="col" style={{position:'relative'}}>
                                                <Ring 
                                                    Status ={currRequest?.Approval3}
                                                    Text = {currRequest?.Approval3 == 'pending'? 'Pending approval from CEO' : `Request has been ${currRequest?.Approval3} by ${currRequest?.Approval3ByName}`}
                                                />

                                                {currRequest?.Approval3 !== 'denied' &&
                                                    <div className={`connectLine ${currRequest?.ApprovalRequired >=4 && animate ? 'active' : '' } ${currRequest?.Approval3 == 'approved'? 'approved' : ''}`} />
                                                }
                                            </div>
                                        }

                                        {currRequest?.ApprovalRequired >= 4 && currRequest?.Approval3 !== 'denied' && currRequest?.Approval2 !== 'denied' && currRequest?.Approval1 !== 'denied' &&
                                            <div className="col" style={{position:'relative'}}>
                                                <Ring 
                                                    Status ={currRequest?.Approval4}
                                                    Text = {currRequest?.Approval4 == 'pending'? 'Pending approval from President' : `Request has been ${currRequest?.Approval4} by ${currRequest?.Approval2ByName}`}
                                                />
                                                
                                            </div>
                                        }
                                    </div>
                                </div>

                            
                            </div>
                        </div>

                        <br/>

                        <div className="row printpage">
                            <div className="col-12">
                                <div className="row blue-card g-2 needs-validation testform" novalidate>
                                    <h5>Purchase</h5>
                                    <div className="col-xl-6 col-sm-12">
                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="basic-addon1">Asset Category</span>
                                            <input className="form-control" value={currRequest?.AssetCategory} disabled  />
                                        </div>

                                        <div className="input-group mb-3 flex-nowrap">
                                            <span className="input-group-text" id="basic-addon1">Quantity</span>
                                            <input className="form-control" value={currRequest?.Quantity} disabled  />
                                        </div>
                                        
                                        <div className="input-group mb-3">
                                            <span className="input-group-text " id="basic-addon1">Justification of Purchase</span>
                                            <div className="form-control custom">{currRequest?.JustificationOfPurchase}</div>
                                        </div>

                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="basic-addon1">Ship To Address</span>
                                            <div className="form-control custom">{currRequest?.ShipToAddress}</div>
                                        </div>
                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="basic-addon1">City/State/Zip</span>
                                            <div className="form-control custom">{currRequest?.AddressTo}</div>
                                        </div>
                                    </div>
                                    

                                    <div className="col-xl-6 col-sm-12">
                                        <div className="input-group mb-3">
                                                <span className="input-group-text" id="basic-addon1">CAPEX or PURCHASE REQ</span>
                                                <input className="form-control" value={currRequest?.ID} disabled  />
                                            </div>
                                            
                                            <div className="input-group mb-3" style={{position:'relative'}}>
                                                <span className="input-group-text" id="basic-addon1">Part Number</span>
                                                <input className="form-control" value={currRequest?.PartNumber} disabled  />
                                            </div>
                                            
                                            <div className="input-group mb-3">
                                                <span className="input-group-text" id="basic-addon1">Item Description</span>
                                                <div className="form-control custom">{currRequest?.ItemDescription}</div>
                                            </div>

                                            <div className="input-group mb-3">
                                                <span className="input-group-text" id="basic-addon1">Location</span>
                                                <div className="form-control custom">{currRequest?.AddressFrom}</div>
                                            </div>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text" id="basic-addon1">City/State/Zip</span>
                                                <div className="form-control custom">{currRequest?.Location}</div>
                                            </div>
                                        </div>
                                    
                                </div>
                            </div>
                        </div>

                        <br/>

                        <div className="row printpage">
                            <div className="col-12">
                                <div className="blue-card g-2 row">
                                    <h5>Cost</h5>
                                    <div className="col-xl-6 col-sm-12">
                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="basic-addon1">Vendor</span>
                                            <input className="form-control" value={currRequest?.Vendor} disabled  />
                                        </div>

                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="basic-addon1">Quote #</span>
                                            <input className="form-control" value={currRequest?.Quote} disabled  />
                                        </div>

                                        <div className="input-group mb-3 printhide">
                                            <span className="input-group-text" id="basic-addon1">Quote Attachment</span>

                                            {downloadQuote?
                                                (
                                                    <>
                                                        <input className="form-control" disabled />
                                                        <a className='quoteDownload' href={downloadQuote} download={currRequest?.ID + `_quote.${downloadFormat}`}>Download</ a>
                                                    </>
                                                    
                                                        
                                                    
                                                )

                                                :

                                                (
                                                    <input className="form-control" value='There is no attached quote' disabled  />
                                                )
                                            }
                                            
                                        </div>

                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="basic-addon1">Department</span>
                                            <input className="form-control" value={currRequest?.Department} disabled  />
                                        </div>


                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="basic-addon1">Budgeted</span>
                                            <input className="form-control" value={currRequest?.Budgeted == 'true'? 'Yes' : 'No'} disabled  />
                                        </div>


                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="basic-addon1">Unbudgeted ROI</span>
                                            <input className="form-control" value={currRequest?.Unbudgeted? currRequest?.Unbudgeted : 'N/A'} disabled  />
                                        </div>
                                    </div>
                                    

                                    <div className="col-xl-6 col-sm-12">
                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="basic-addon1">Quote Date</span>
                                            <input className="form-control" value={currRequest?.QuoteDate} disabled  />
                                            
                                        </div>
                                            
                                        <div className="input-group mb-3" style={{position:'relative'}}>
                                            <span className="input-group-text" id="basic-addon1">Estimated Value</span>
                                            <input className="form-control" value={formatNumberWithCommasAndDots(currRequest?.EstimatedValue)} disabled  />
                                            <span className="input-group-text">$</span>
                                        
                                        </div>
                                        
                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="basic-addon1">Project Code</span>
                                            <input className="form-control" value={currRequest?.ProjectCode} disabled  />
                                        </div>

                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="basic-addon1">Budget Code</span>
                                            <input className="form-control" value={currRequest?.BudgetCode} disabled  />
                                        </div>

                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="basic-addon1">Budget Amount</span>
                                            <input className="form-control" value={formatNumberWithCommasAndDots(currRequest?.BudgetAmount)} disabled  />
                                            <span className="input-group-text">$</span>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    
                        <br/>

                        <div class="row printpage" style={{marginBottom:'1%'}}>

                            <div class="col-12">
                                <div className="blue-card row g-2">
                                    <h5>Contact</h5>
                                    <div className="col-xl-6 col-sm-12">
                                        <div class="input-group mb-3">
                                            <span class="input-group-text" id="basic-addon1">Originator</span>
                                            <input className="form-control" value={currRequest?.OriginatorName} disabled  />
                                        </div>


                                        <div class="input-group mb-3">
                                            <span class="input-group-text" id="basic-addon1">Contact Phone Number</span>
                                            <input className="form-control" value={currRequest?.Phone} disabled  />
                                        </div>


                                    
                                    </div>

                                    <div className="col-xl-6 col-sm-12">
                                    
                                        <div class="input-group mb-3">
                                            <span class="input-group-text" id="basic-addon1">Request Date</span>
                                            <input className="form-control" value={currRequest?.DateRequest} disabled  />
                                        </div>

                                        <div class="input-group mb-3">
                                            <span class="input-group-text" id="basic-addon1">Date Needed</span>
                                            <input className="form-control" value={currRequest?.DateNeeded} disabled  />
                                        
                                        </div>
                                    </div>
                                    

                                
                                </div>
                            </div>


                        </div>

                        
                        <div className="row mb-5 toprint printhide">
                            <div className="col-12">
                                <div className="blue-card row bottomApproval g-2" style={{justifyContent:'center'}}>
                                    <div 
                                        style={{borderRadius:100}} 
                                        className="btn custom dld btn-primary"
                                        data-bs-dismiss="modal"
                                        onClick={() => setTimeout(() => {
                                            printRequest()
                                        }, 500)}
                                        
                                    >
                                        Download PDF
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
           
           
        </div>
    )
}

export default Requests